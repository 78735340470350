import { init as initialiseSentry } from "@sentry/react"
import { replaceUnsafe } from "@swan-io/chicane"
import * as Fathom from "fathom-client"
import { render } from "preact"

import { api } from "#api"
import { App, appId, appStore, auth0Client } from "#app"
import { metricsApi } from "#metrics"

const utmCacheKey = "UTM_CACHE"

declare global {
	interface Window {
		_hsp: Array<unknown>
	}
}

// Set the Gf-Client Header for all requests
api.axios.defaults.headers.common["GF-Client"] = appId

if (location.origin === "https://app.glassflow.dev") {
	initialiseSentry({
		release: APP_VERSION,
		dsn: "https://40c23071ed1f3ec9d396c62cd2b46eeb@o4507096678793216.ingest.de.sentry.io/4507096681218128",
	})
}

let utmCache = localStorage.getItem(utmCacheKey)

if (utmCache) {
	localStorage.removeItem(utmCacheKey)
} else {
	const url = new URL(location.toString())

	utmCache = JSON.stringify({
		utm_source: url.searchParams.get("utm_source"),
		utm_medium: url.searchParams.get("utm_medium"),
		utm_campaign: url.searchParams.get("utm_campaign"),
		utm_content: url.searchParams.get("utm_content"),
		utm_term: url.searchParams.get("utm_term"),
	})

	localStorage.setItem(utmCacheKey, utmCache)
}

if (location.hash === "#loginSuccess") {
	auth0Client
		.handleRedirectCallback()
		.then(async (result) => {
			replaceUnsafe(result.appState.location)

			await appStore.detectAuthenticationState()
			if (
				appStore.profile &&
				location.origin === "https://app.glassflow.dev"
			) {
				metricsApi.postMetricEvents(
					[
						{
							name: "user_login",
							created_at: new Date().toISOString(),
							data: {
								user_id: appStore.profile.id,
								email: appStore.profile.email,
								timestamp: new Date().toISOString(),
								is_signup: false,
								...JSON.parse(utmCache ?? "{}"),
							},
							metadata: {
								source: appId,
							},
						},
					],
					{},
				)
			}
			Fathom.trackEvent("Login Success")
		})
		.catch((error) => {
			console.log(error)
			Fathom.trackEvent("Login Failed")
		})
} else if (location.hash === "#signUpSuccess") {
	auth0Client
		.handleRedirectCallback()
		.then(async (result) => {
			replaceUnsafe(result.appState.location)
			await appStore.detectAuthenticationState({ isSignUp: true })
			if (
				appStore.profile &&
				location.origin === "https://app.glassflow.dev"
			) {
				metricsApi.postMetricEvents(
					[
						{
							name: "user_login",
							created_at: new Date().toISOString(),
							data: {
								user_id: appStore.profile.id,
								email: appStore.profile.email,
								timestamp: new Date().toISOString(),
								is_signup: true,
								...JSON.parse(utmCache ?? "{}"),
							},
							metadata: {
								source: appId,
							},
						},
					],
					{},
				)
			}
			Fathom.trackEvent("SignUp Success")
		})
		.catch((error) => {
			console.log(error)
			Fathom.trackEvent("SignUp Failed")
		})
}

appStore.detectAuthenticationState()

let appElement = document.getElementById("app")
if (appElement) {
	render(<App />, appElement)
}

Fathom.load("XQLZHYKJ", { spa: "history" })
