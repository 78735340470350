import { types } from "mobx-state-tree"

export const OrganisationModel = types.model("Organisation", {
	id: types.string,
	name: types.string,
})

export const ProfileModel = types.model("Profile", {
	id: types.identifier,
	home_organization: OrganisationModel,
	name: types.string,
	email: types.string,
	provider: types.string,
	external_settings: types.maybeNull(types.frozen()),
})

export const PipelineModel = types.model("Pipeline", {
	id: types.identifier,
	name: types.string,
	space_id: types.string,
	space_name: types.maybe(types.string),
	source_connector: types.maybeNull(
		types.union(
			types.model({
				kind: types.literal("google_pubsub"),
				config: types.model({
					project_id: types.string,
					subscription_id: types.string,
					credentials_json: types.string,
				}),
			}),
			types.model({
				kind: types.literal("amazon_sqs"),
				config: types.model({
					queue_url: types.string,
					aws_region: types.string,
					aws_access_key: types.string,
					aws_secret_key: types.string,
				}),
			}),
		),
	),
	sink_connector: types.maybeNull(
		types.union(
			types.model({
				kind: types.literal("webhook"),
				config: types.model({
					url: types.string,
					method: types.optional(
						types.union(
							types.literal("GET"),
							types.literal("POST"),
							types.literal("PUT"),
							types.literal("PATCH"),
							types.literal("DELETE"),
						),
						"POST",
					),
					headers: types.frozen(),
				}),
			}),
			types.model({
				kind: types.literal("clickhouse"),
				config: types.model({
					addr: types.string,
					database: types.string,
					username: types.string,
					password: types.string,
					table: types.string,
				}),
			}),
		),
	),
	metadata: types.maybeNull(types.frozen()),
	created_at: types.string,
})

export const SpaceModel = types.model("Space", {
	id: types.identifier,
	name: types.string,
	permission: types.string,
	created_at: types.string,
})

export const PipelinesModel = types.model("Pipelines", {
	pipelines: types.array(
		types.model("Pipeline", {
			id: types.identifier,
			name: types.string,
			space_id: types.string,
			space_name: types.maybe(types.string),
			metadata: types.maybeNull(types.frozen()),
			created_at: types.string,
		}),
	),
	count: types.optional(types.number, 0),
})

export const SpacesModel = types.model("Spaces", {
	spaces: types.array(SpaceModel),
})

export const AccessTokenModel = types.model("AccessToken", {
	id: types.identifier,
	name: types.string,
	token: types.string,
	created_at: types.string,
})

export const LogModel = types.model("Log", {
	level: types.string,
	severity_code: types.number,
	timestamp: types.string,
	payload: types.frozen<{ message: string }>(),
})
