import classNames from "classnames"
import { observer } from "mobx-react"
import { Suspense, createRef, useEffect } from "preact/compat"
import { Check, Save, Settings, Upload } from "react-feather"
import {
	Button,
	FormControl,
	HiddenVisually,
	Popover,
	Switch,
	Tabs,
	View,
	useTheme,
	useToast,
} from "reshaped"

import { AceEditor } from "#components/AceEditor"

import { pipelineDetailPageStore } from "../page.model"
import css from "./Transformer.module.css"

export const Transformer = observer(() => {
	const { colorMode } = useTheme()
	const toast = useToast()

	const fileInput = createRef<HTMLInputElement>()
	const editor = createRef()

	useEffect(() => {
		if (editor.current) {
			editor.current.editor.setOption(
				"wrap",
				pipelineDetailPageStore.transformer.editorSettings.softWrap,
			)

			editor.current.editor.setOption(
				"showInvisibles",
				pipelineDetailPageStore.transformer.editorSettings
					.showInvisibles,
			)
		}
	}, [editor])

	return (
		<View direction="column" gap={3}>
			<View direction="row" gap={3}>
				<Popover position="bottom-start">
					<Popover.Trigger>
						{(attributes) => (
							<Button
								attributes={attributes}
								icon={Settings}
								variant="faded"
								color="primary"
							>
								Editor Settings
							</Button>
						)}
					</Popover.Trigger>
					<Popover.Content>
						<View direction="column" gap={4}>
							<Switch
								size="small"
								name="softWrap"
								checked={
									pipelineDetailPageStore.transformer
										.editorSettings.softWrap
								}
								onChange={({ checked }) => {
									pipelineDetailPageStore.transformer.editorSettings.setSoftWrap(
										checked,
									)
								}}
							>
								Soft Wrap
							</Switch>
							<Switch
								size="small"
								name="showInvisibles"
								checked={
									pipelineDetailPageStore.transformer
										.editorSettings.showInvisibles
								}
								onChange={({ checked }) => {
									pipelineDetailPageStore.transformer.editorSettings.setShowInvisibles(
										checked,
									)
								}}
							>
								Show Invisibles
							</Switch>
						</View>
					</Popover.Content>
				</Popover>

				<View grow align="end">
					<View direction="row" gap={3}>
						<HiddenVisually>
							<input
								type="file"
								ref={fileInput}
								accept=".py"
								onChange={() => {
									const files = fileInput.current?.files

									if (files?.length) {
										const file = files[0]

										let reader = new FileReader()

										reader.addEventListener(
											"load",
											(event) => {
												if (
													typeof event.target
														?.result === "string"
												) {
													pipelineDetailPageStore.transformer.form.setHandler(
														event.target.result,
													)
												}
											},
										)

										reader.readAsText(file, "UTF-8")
									}
								}}
							/>
						</HiddenVisually>
						<Button
							variant="faded"
							color="primary"
							onClick={() => {
								fileInput.current?.click()
							}}
							icon={Upload}
						>
							Upload Handler File
						</Button>
						<Button
							variant="solid"
							color="positive"
							onClick={() => {
								pipelineDetailPageStore.saveTransformer(
									async () => {
										const id = toast.show({
											color: "neutral",
											timeout: 5000,
											icon: Check,
											title: "Transformer saved.",
											actionsSlot: (
												<Button
													variant="faded"
													color="inherit"
													onClick={() =>
														toast.hide(id)
													}
												>
													Dismiss
												</Button>
											),
										})
									},
								)
							}}
							disabled={
								!pipelineDetailPageStore.transformer.form
									.hasChanges
							}
							loading={pipelineDetailPageStore.isSaving}
							icon={Save}
						>
							Save Transformer
						</Button>
					</View>
				</View>
			</View>
			<FormControl
				hasError={
					pipelineDetailPageStore.transformer.form.handler.hasError
				}
			>
				<Tabs>
					<Tabs.List>
						<Tabs.Item value="handler">handler.py</Tabs.Item>
						<Tabs.Item value="requirements">
							requirements.txt
						</Tabs.Item>
					</Tabs.List>

					<Tabs.Panel value="handler">
						<View
							minHeight="300px"
							height="90dvh"
							maxHeight="calc(100dvh - 400px)"
							paddingTop={3}
						>
							<Suspense fallback={null}>
								<AceEditor
									ref={editor}
									onLoad={(editorInstance) => {
										editorInstance.setOption(
											"wrap",
											pipelineDetailPageStore.transformer
												.editorSettings.softWrap,
										)

										editorInstance.setOption(
											"showInvisibles",
											pipelineDetailPageStore.transformer
												.editorSettings.showInvisibles,
										)
									}}
									className={classNames({
										[css.editor]: true,
										[css.editorError]:
											pipelineDetailPageStore.transformer
												.form.handler.hasError,
									})}
									mode="python"
									theme={
										colorMode === "dark"
											? "dracula"
											: "xcode"
									}
									onChange={(value) => {
										pipelineDetailPageStore.transformer.form.setHandler(
											value,
										)
									}}
									value={
										pipelineDetailPageStore.transformer.form
											.handler.value
									}
									lineHeight="1.65"
									width="100%"
									height="100%"
									fontSize={14}
									showGutter
									tabSize={4}
									readOnly={pipelineDetailPageStore.isSaving}
									enableBasicAutocompletion
									enableLiveAutocompletion
									showPrintMargin={false}
									editorProps={{ $blockScrolling: true }}
								/>
							</Suspense>

							<FormControl.Error>
								{
									pipelineDetailPageStore.transformer.form
										.handler.errorMessage
								}
							</FormControl.Error>
						</View>
					</Tabs.Panel>
					<Tabs.Panel value="requirements">
						<View
							minHeight="300px"
							height="90dvh"
							maxHeight="calc(100dvh - 400px)"
							paddingTop={3}
						>
							<Suspense fallback={null}>
								<AceEditor
									ref={editor}
									onLoad={(editorInstance) => {
										editorInstance.setOption(
											"wrap",
											pipelineDetailPageStore.transformer
												.editorSettings.softWrap,
										)

										editorInstance.setOption(
											"showInvisibles",
											pipelineDetailPageStore.transformer
												.editorSettings.showInvisibles,
										)
									}}
									className={classNames({
										[css.editor]: true,
										[css.editorError]:
											pipelineDetailPageStore.transformer
												.form.requirements.hasError,
									})}
									mode="python"
									theme={
										colorMode === "dark"
											? "dracula"
											: "xcode"
									}
									onChange={(value) => {
										pipelineDetailPageStore.transformer.form.setRequirements(
											value,
										)
									}}
									value={
										pipelineDetailPageStore.transformer.form
											.requirements.value
									}
									lineHeight="1.65"
									width="100%"
									height="100%"
									fontSize={14}
									showGutter
									tabSize={4}
									readOnly={pipelineDetailPageStore.isSaving}
									enableBasicAutocompletion
									enableLiveAutocompletion
									showPrintMargin={false}
									editorProps={{ $blockScrolling: true }}
								/>
							</Suspense>

							<FormControl.Error>
								{
									pipelineDetailPageStore.transformer.form
										.handler.errorMessage
								}
							</FormControl.Error>
						</View>
					</Tabs.Panel>
				</Tabs>
			</FormControl>
		</View>
	)
})
