import { Auth0Client } from "@auth0/auth0-spa-js"
import * as Fathom from "fathom-client"
import { autorun } from "mobx"
import { type Instance, flow, getSnapshot, types } from "mobx-state-tree"

import { ProfileModel, api, isApiError } from "#api"

export const TokenModel = types.model("Auth0 Tokens", {
	id_token: types.string,
	access_token: types.string,
	expires_in: types.number,
})

export const AppModel = types
	.model("App", {
		userSettings: types.optional(
			types
				.model("UserSettings", {
					theme: types.optional(
						types.union(
							types.literal("automatic"),
							types.literal("dark"),
							types.literal("light"),
						),
						"automatic",
					),
				})
				.actions((self) => {
					const setTheme = (options: {
						theme: "dark" | "light" | "automatic"
						persistSettings?: boolean
					}) => {
						self.theme = options.theme

						if (options.persistSettings) {
							Fathom.trackEvent("Setting:Theme Set")
							Fathom.trackEvent(
								`Setting:Theme Set=${options.theme}`,
							)
							api.SaveUserExternalSettings(
								getSnapshot<Record<string, unknown>>(self),
							)
						}
					}

					return {
						setTheme,
					}
				}),
			{},
		),
		systemTheme: types.optional(
			types.union(types.literal("dark"), types.literal("light")),
			"dark",
		),
		auth: types.optional(
			types.union(
				types.model("Unauthenticated", {
					isAuthenticated: types.literal(false),
					isDetectingState: types.optional(types.boolean, true),
				}),
				types.model("Unauthenticated", {
					isAuthenticated: types.literal(true),
					isDetectingState: types.optional(types.boolean, false),
					tokens: TokenModel,
				}),
			),
			{ isAuthenticated: false, isDetectingState: true },
		),
		profile: types.maybe(ProfileModel),
	})
	.actions((self) => {
		const detectAuthenticationState = flow(function* (
			options: { isSignUp: boolean } = { isSignUp: false },
		) {
			self.auth.isDetectingState = true
			let tokens: Instance<typeof TokenModel> | undefined = undefined

			try {
				tokens = TokenModel.create(
					yield auth0Client.getTokenSilently({
						detailedResponse: true,
					}),
				)
			} catch (error) {
				self.auth.isDetectingState = false
			}

			if (tokens) {
				if (options.isSignUp) {
					try {
						const response = ProfileModel.create(
							yield api.signUp({
								access_token: tokens.access_token,
								id_token: tokens.id_token,
							}),
						)

						const excludedEmails = ["ashishbagri@gmail.com"]

						if (
							response.email.endsWith("glassflow.dev") ||
							excludedEmails.includes(response.email)
						) {
							Fathom.blockTrackingForMe()
						}

						self.profile = response
						self.userSettings = response.external_settings
					} catch (error) {
						if (isApiError("signUp", error)) {
							// User already exists, so lets get the profile the usual way
							if (error.response.status === 409) {
								const response = ProfileModel.create(
									yield api.getProfile({
										headers: {
											Authorization: `Bearer ${tokens.access_token}`,
										},
									}),
								)

								self.profile = response
								self.userSettings =
									response.external_settings ?? {}
							}
						}
					}
				} else {
					try {
						const response = yield api.getProfile({
							headers: {
								Authorization: `Bearer ${tokens.access_token}`,
							},
						})

						self.profile = response
						self.userSettings = response.external_settings ?? {}
					} catch (error) {
						if (isApiError("getProfile", error)) {
							if (error.response.status === 401) {
								const response = ProfileModel.create(
									yield api.signUp({
										access_token: tokens.access_token,
										id_token: tokens.id_token,
									}),
								)

								self.profile = response
								self.userSettings =
									response.external_settings ?? {}
							}
						}
					}
				}

				// Set the Authorization header for all following requests
				api.axios.defaults.headers.common.Authorization = `Bearer ${tokens.access_token}`

				self.auth = {
					isAuthenticated: true,
					isDetectingState: false,
					tokens,
				}
			}
		})

		const logout = () => {
			Fathom.trackEvent("Logout")

			// Reset the global Auth Header
			api.axios.defaults.headers.common.Authorization = undefined
			auth0Client.logout({
				logoutParams: {
					returnTo: location.origin,
				},
			})
		}

		const setSystemTheme = (preference: "dark" | "light") => {
			self.systemTheme = preference
		}

		return {
			detectAuthenticationState,
			logout,
			setSystemTheme,
		}
	})
	.views((self) => {
		const resolvedTheme = (): "dark" | "light" => {
			const userTheme = self.userSettings.theme

			if (userTheme === "automatic") {
				return self.systemTheme
			} else {
				return userTheme
			}
		}

		return { resolvedTheme }
	})

export const appStore = AppModel.create({})

export const auth0Client = new Auth0Client({
	clientId: "DXnSil4YeIhICUWXbggDAHPw334FXXO5",
	domain: "https://auth.glassflow.dev",
	authorizationParams: {
		redirect_uri: location.origin,
		audience: "https://api.glassflow.dev",
	},
})

// #region Initial Theme Setup

const media = window.matchMedia("(prefers-color-scheme: dark)")
const mediaChangeHandler = (event: { matches: boolean }) => {
	if (event.matches) {
		appStore.setSystemTheme("dark")
	} else {
		appStore.setSystemTheme("light")
	}
}

media.addEventListener("change", mediaChangeHandler)

mediaChangeHandler(media) // Run once initially to set up

const localStorageThemeSetting = localStorage.getItem("themeSetting") as
	| null
	| "automatic"
	| "dark"
	| "light"

appStore.userSettings.setTheme({
	theme: localStorageThemeSetting ?? "automatic",
})

autorun(() => {
	localStorage.setItem("themeSetting", appStore.userSettings.theme)
})

// #endregion
